import { useForm, Controller, useFieldArray } from 'react-hook-form'

import { useStore } from 'effector-react'

import {
  Select,
  ActionPopupWrapper,
  SelectByGroupsMultiple,
  Checkbox,
} from '@gmini/ui-kit'

import { useEffect, useMemo } from 'react'

import { AttributeFieldItem, FieldError, FieldLabel } from '@gmini/components'

import { AssigneeListItem, useAssignees } from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import {
  assigneeAllUserList$,
  assigneeRoleList$,
  assigneeCompanyList$,
  assigneeGroupList$,
} from '../../assigneeGroupList'

import { attributesFormService, attributesService } from '../../attribute.store'

import { fetchMostRecentIssuePending$ } from '../../issue.store'

import {
  AttributesWrapper,
  EmptyContainer,
  FieldContainer,
  FullSeparator,
  MultipleFieldRow,
  Form,
  TextArea,
  TextField,
  FieldContainerLine,
} from './UpsertIssueTemplatePopup.styled'

import { openPopup$, toggleOpenPopup } from './upsertIssueTemplatePopup.store'
import { FormValueUpsertIssueTemplate } from './UpsertIssueTemplatePopup.types'

type UpsertIssueTemplatePopupProps = {
  issueTemplate: api.IssueTemplate | null
  onSubmit: (data: FormValueUpsertIssueTemplate) => void
  projectUsers: smApi.Auth.UserData[]
  allUsers: smApi.Auth.UserData[]
  title: string
  submitButtonTitle: string
  onClose?: () => void
  projectList: smApi.Project[]
  projectUrn: string | null
}

export const UpsertIssueTemplatePopup = ({
  onSubmit,
  issueTemplate,
  projectUsers,
  allUsers,
  submitButtonTitle,
  title,
  onClose,
  projectList,
  projectUrn,
}: UpsertIssueTemplatePopupProps) => {
  const initTemplateAttributes = useMemo(
    () => issueTemplate?.attributes || [],
    [issueTemplate?.attributes],
  )

  const attributes = attributesFormService.useAttributesForEditing(
    initTemplateAttributes,
    projectUrn,
  )
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValueUpsertIssueTemplate>({
    mode: 'onChange',
    defaultValues: {
      assignees: [],
      daysToDeadline: null,
      isWorkdaysToDeadline: false,
      description: '',
      name: '',
      project: null,
      attributes: [],
    },
  })

  const { fields: attributesFields } = useFieldArray({
    control,
    name: 'attributes',
  })

  const fetchAttributePendingMap = useStore(
    attributesService.fetchAttributePendingMap$,
  )

  const fetchMostRecentIssuePending = useStore(fetchMostRecentIssuePending$)

  const fetchAttributeValuesPending = useStore(
    attributesService.fetchAttributeValuesPending$,
  )

  const assigneeGroupList = useStore(assigneeGroupList$)

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn),
    [projectList, projectUrn],
  )

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const attributeValues = useStore(attributesFormService.attributeValues$)

  const preparedTemplateAttributes = useMemo(
    () =>
      attributesFormService.getFormAttributes(
        attributes,
        issueTemplate?.attributes || [],
        attributeValues,
      ),
    [attributeValues, attributes, issueTemplate],
  )

  useEffect(() => {
    reset({
      assignees: issueTemplate ? getAssignees(issueTemplate.assignees) : [],
      description: issueTemplate?.description || '',
      name: issueTemplate?.name || '',
      daysToDeadline: issueTemplate?.daysToDeadline || null,
      isWorkdaysToDeadline: issueTemplate?.isWorkdaysToDeadline || false,
      project:
        projectList.find(({ urn }) => urn === issueTemplate?.projectUrn) ||
        selectedProject,
      attributes: preparedTemplateAttributes,
    })
  }, [
    selectedProject,
    projectList,
    issueTemplate,
    reset,
    allUsers,
    getAssignees,
    preparedTemplateAttributes,
  ])

  const open = useStore(openPopup$)

  const onCloseHandler = () => {
    toggleOpenPopup(false)
    onClose?.()
    reset()
  }

  const onSubmitHandler = (data: FormValueUpsertIssueTemplate) => {
    onSubmit(data)

    onCloseHandler()
  }

  return (
    <ActionPopupWrapper
      open={open}
      onClose={onCloseHandler}
      onSubmit={handleSubmit(onSubmitHandler)}
      width='600px'
      submitButtonTitle={submitButtonTitle}
      title={title}
      dataTestIdFor={{
        declineButton: 'upsertIssueTemplatePopupDeclineBtn',
        acceptButton: 'upsertIssueTemplatePopupCreateBtn',
      }}
    >
      {({ existScroll }) => (
        <Form>
          {!issueTemplate && (
            <FieldContainer>
              <FieldLabel required>Проект</FieldLabel>
              <Controller
                name='project'
                control={control}
                rules={{
                  required: { message: requiredErrorMessage, value: true },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={projectList}
                    getOptionLabel={(option: smApi.Project) => option.name}
                    placeholder='Выберите проект'
                    error={Boolean(errors.project)}
                    emptyOptionListMessage={emptyOptionListMessage}
                    allowDelete={false}
                    lockSelect={!!selectedProject}
                  />
                )}
              />
              <FieldError hidden={!errors.project}>
                {errors.project && 'message' in errors.project
                  ? errors.project.message
                  : null}
              </FieldError>
            </FieldContainer>
          )}
          <FieldContainer>
            <FieldLabel required>Название</FieldLabel>
            <Controller
              name='name'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
                maxLength: {
                  message: maxLengthTitleErrorMessage,
                  value: 256,
                },
              }}
              render={({ field }) => (
                <TextField
                  placeholder='Укажите название'
                  error={Boolean(errors.name)}
                  onChange={field.onChange}
                  value={field.value}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.name}>
              {errors.name && 'message' in errors.name
                ? errors.name.message
                : null}
            </FieldError>
          </FieldContainer>
          <MultipleFieldRow>
            <FieldContainer>
              <FieldLabel>Назначить на</FieldLabel>

              <Controller
                name='assignees'
                control={control}
                render={({ field }) => (
                  <SelectByGroupsMultiple
                    {...field}
                    getOptionLabel={(option: AssigneeListItem) => option.label}
                    groups={assigneeGroupList}
                    placeholder='Выберите пользователя/роль/компанию'
                    error={Boolean(errors.assignees)}
                    optionDataTestIdPrefix='upsertIssueTemplatePopupSelectAssigneeOption'
                  />
                )}
              />
              <FieldError hidden={!errors.assignees}>
                {errors.assignees && 'message' in errors.assignees
                  ? (errors.assignees as { message: string }).message //TODO убрать каст
                  : null}
              </FieldError>
            </FieldContainer>
          </MultipleFieldRow>
          <FieldContainer>
            <FieldLabel>Описание</FieldLabel>

            <Controller
              name='description'
              control={control}
              rules={{
                maxLength: {
                  value: 2048,
                  message: maxLengthDescriptionErrorMessage,
                },
              }}
              render={({ field }) => (
                <TextArea
                  placeholder='Укажите описание'
                  error={Boolean(errors.description)}
                  {...field}
                />
              )}
            />

            <FieldError hidden={!errors.description}>
              {errors.description && 'message' in errors.description
                ? errors.description.message
                : null}
            </FieldError>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>Срок дней</FieldLabel>

            <Controller
              name='daysToDeadline'
              control={control}
              rules={{
                validate: v => {
                  if (v === null) {
                    return true
                  }
                  const numVal = Number(v)
                  const isWholeNumber =
                    !isNaN(numVal) && Number.isInteger(numVal)
                  return (
                    (isWholeNumber && numVal >= 1 && numVal <= 366) ||
                    shouldBeNumberErrorMessage
                  )
                },
              }}
              render={({ field }) => (
                <TextField
                  placeholder='Укажите количество дней'
                  error={Boolean(errors.daysToDeadline)}
                  onChange={v => {
                    if (v === '') {
                      field.onChange(null)
                      return
                    }
                    field.onChange(v)
                  }}
                  value={field.value?.toString() || ''}
                  onBlur={() => {
                    // eslint-disable-next-line radix
                    const numVal = parseInt(field.value?.toString() || '')
                    field.onChange(!isNaN(numVal) ? numVal : null)
                  }}
                  clearable
                />
              )}
            />

            <FieldError hidden={!errors.daysToDeadline}>
              {errors.daysToDeadline && 'message' in errors.daysToDeadline
                ? errors.daysToDeadline.message
                : null}
            </FieldError>
          </FieldContainer>
          <FieldContainerLine>
            <Controller
              name='isWorkdaysToDeadline'
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={!!field.value || false}
                  onChange={field.onChange}
                />
              )}
            />
            <FieldLabel>Считать в рабочих днях</FieldLabel>
          </FieldContainerLine>

          <FullSeparator />

          <AttributesWrapper>
            {attributesFields.map((attribute, index) => (
              <AttributeFieldItem<FormValueUpsertIssueTemplate>
                key={attribute.id}
                deleted={attribute.deleted}
                index={index}
                control={control}
                attributeId={attribute.attributeId}
                attributeName={attribute.name}
                attributesService={attributesService}
                loading={!!fetchAttributePendingMap[attribute.attributeId]}
                optionsLoading={
                  fetchMostRecentIssuePending || fetchAttributeValuesPending
                }
                attributesFormService={attributesFormService}
                dataTestIdPrefix='upsertIssueTemplatePopupSelectAttribute'
                projectUrn={projectUrn}
              />
            ))}
          </AttributesWrapper>

          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}

const emptyOptionListMessage = 'Нет доступных совпадений'
const maxLengthTitleErrorMessage =
  'Максимальная допустимая длина названия - 256 символов'
const requiredErrorMessage = 'Это поле является обязательным'
const maxLengthDescriptionErrorMessage =
  'Максимальная допустимая длина описания - 2048 символа'
const shouldBeNumberErrorMessage =
  'Введенное значение должно быть целым числом от 1 до 366'
