import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { useStore, useStoreMap } from 'effector-react'

import {
  Button,
  DatePicker,
  Select,
  SelectByGroupsMultiple,
  Tooltip,
  UploadedFile,
} from '@gmini/ui-kit'

import { interfaceName } from '@gmini/common'

import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { isNotEmpty } from '@gmini/utils'

import {
  AssigneeListItem,
  ErrorMessage,
  fileValidationRules,
  maxCountErrorMessage,
  maxCountFiles,
  useAssignees,
  useFilesValidation,
} from '@gmini/helpers'

import {
  AttributeFieldItem,
  ColoredSelect,
  FieldError,
  FieldLabel,
} from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import dayjs from 'dayjs'

import { FileValidationErrorsPopupProps } from '@gmini/ui-common'

import { debounce } from '@mui/material'

import { AttachFile } from '../../atoms'

import {
  createIssueWithFiles,
  createIssueWithFilesPending$,
  fetchMostRecentIssuePending$,
} from '../../issue.store'

import { FormValue } from '../../createIssuePopup.store'

import { DEFAULT_DISPLAY_DATE_FORMAT, ZERO_SEARCH } from '../../../constants'

import {
  assigneeAllUserList$,
  assigneeCompanyList$,
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeUserList$,
} from '../../assigneeGroupList'

import { issueStatusList$ } from '../../issueStatus.store'

import { attributesFormService, attributesService } from '../../attribute.store'

import { addDaysToDate, addWorkdaysToDate } from '../../../helpers'
import { seoEventManager } from '../../../config'

import {
  fetchIssueTemplateList,
  resetTemplateList,
  templateList$,
} from './model'

import {
  AttributesWrapper,
  ColorSelectWrapper,
  Container,
  Content,
  CreateButton,
  EmptyContainer,
  FieldContainer,
  Footer,
  Form,
  FullSeparator,
  Header,
  HeaderTitle,
  TextArea,
  TextField,
  Title,
  UploadFilesWrapper,
} from './CreateIssue.styled'

const maxLengthTitleErrorMessage =
  'Максимальная допустимая длина названия - 256 символов'
const maxLengthDescriptionErrorMessage =
  'Максимальная допустимая длина описания - 2048 символа'
const requiredErrorMessage = 'Это поле является обязательным'
const failDateErrorMessage = 'Некорректная дата'

type CreateIssuePopupProps = {
  onCreateIssue?: (issue: api.Issue.IssuePopulated) => void
  renderFileValidationErrorsPopup?: (
    props: Partial<FileValidationErrorsPopupProps>,
  ) => void
  projectUrn: string | null
  projectList: smApi.Project[]
  onClose?: () => void
  loading?: boolean
  lockProjectSelect?: boolean
  closable?: boolean
}

const templateLimit = 20
const defaultValues: FormValue = {
  assignees: [],
  name: '',
  description: '',
  files: [],
  project: null,
  template: null,
  deadline: null,
  status: null,
  attributes: [],
}

export const CreateIssue = ({
  onCreateIssue,
  projectUrn,
  projectList,
  onClose,
  loading,
  lockProjectSelect,
  renderFileValidationErrorsPopup,
}: CreateIssuePopupProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm<FormValue>({ mode: 'onChange', defaultValues })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  })

  const { fields: attributesFields } = useFieldArray({
    control,
    name: 'attributes',
  })

  const filesState = watch('files')
  const project = watch('project')
  const template = watch('template')
  const assignees = watch('assignees')

  const assigneeUsers = useStore(assigneeUserList$)
  const fetchAttributePendingMap = useStore(
    attributesService.fetchAttributePendingMap$,
  )
  const assigneeGroupList = useStore(assigneeGroupList$)
  const statusList = useStore(issueStatusList$)
  const preparedStatusList = statusList.filter(
    ({ status }) =>
      api.IssueStatus.Enum.NOT_STARTED === status ||
      api.IssueStatus.Enum.DRAFT === status,
  )
  const fetchIssueTemplateListPending = useStore(
    fetchIssueTemplateList.pending$,
  )

  const fetchMostRecentIssuePending = useStore(fetchMostRecentIssuePending$)

  const fetchAttributeValuesPending = useStore(
    attributesService.fetchAttributeValuesPending$,
  )

  const attributes = attributesService.useAttributeList({ projectUrn })

  const [templateSearchValue, setTemplateSearchValue] = useState('')
  const [offsetTemplateList, setOffsetTemplateList] = useState(0)
  const createIssuePending = useStore(createIssueWithFilesPending$)
  const attributeValues = useStore(attributesFormService.attributeValues$)
  const preparedTemplateAttributes = useMemo(
    () =>
      attributesFormService.getFormAttributes(
        attributes,
        template?.attributes || [],
        attributeValues,
      ),
    [attributes, template, attributeValues],
  )

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn),
    [projectList, projectUrn],
  )

  const { templateList, total: totalTemplates } = useStoreMap({
    store: templateList$,
    keys: [templateSearchValue],
    fn: ({ byId$, ids$, totalTemplates$ }, [key]) => {
      const search = key || ZERO_SEARCH
      const idsList = ids$[search]

      if (idsList) {
        return {
          templateList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalTemplates$,
        }
      }

      return { templateList: [], total: null }
    },
  })

  useEffect(() => {
    if (project) {
      resetTemplateList()
      setValue('assignees', [])
      setValue('description', '')
      setValue('name', '')
      setValue('template', null)
      setValue('deadline', null)
      setValue('status', null)
    }
  }, [project, setValue])

  useEffect(() => {
    setValue(
      'attributes',
      attributes.map(attr => ({
        attributeId: attr.id,
        name: attr.name,
        deleted: attr.deleted,
        values: [],
      })),
    )
  }, [attributes, setValue])

  useEffect(() => {
    if (project) {
      fetchIssueTemplateList({
        limit: templateLimit,
        offset: offsetTemplateList,
        filter: templateSearchValue,
        projectUrn: project?.urn,
      })
    }
  }, [offsetTemplateList, templateSearchValue, project])

  const { validateFiles, clearFileErrors } = useFilesValidation({
    rules: fileValidationRules,
    onError: (errors: ErrorMessage[]) =>
      renderFileValidationErrorsPopup?.({
        open: true,
        errors,
        onClose: () =>
          renderFileValidationErrorsPopup?.({
            open: false,
          }),
      }),
  })

  useEffect(() => {
    const setByTemplate = async () => {
      if (template) {
        const assignees = getAssignees(template.assignees)
        setValue('assignees', assignees)
        setValue('description', template.description || '')
        setValue('name', template.name || '')
        setValue('attributes', preparedTemplateAttributes)
        setValue(
          'deadline',
          template.daysToDeadline
            ? template.isWorkdaysToDeadline
              ? dayjs(await addWorkdaysToDate(template.daysToDeadline))
              : dayjs(addDaysToDate(template.daysToDeadline))
            : null,
        )
      }
    }
    setByTemplate()
  }, [
    assigneeUsers,
    attributes,
    getAssignees,
    setValue,
    template,
    preparedTemplateAttributes,
  ])

  useEffect(() => {
    if (selectedProject) {
      setValue('project', selectedProject)
    }
  }, [selectedProject, setValue])

  const onSubmit = async (data: FormValue) => {
    try {
      const {
        name,
        assignees,
        description,
        files,
        project,
        deadline,
        status,
        attributes,
      } = data

      if (!project) {
        throw new Error('Не выбран проект')
      }

      const params: api.Issue.CreateIssueParams = {
        name,
        description,
        projectUrn: project?.urn,
        deadline: deadline ? dayjs(deadline).toISOString() : undefined,
        status,
        assignees,
        attributes: attributes
          .map(attr => ({
            id: attr.attributeId,
            valueIds: attr.values.map(({ id }) => id),
          }))
          .filter(({ valueIds }) => valueIds?.length > 0),
      }

      const issue = await createIssueWithFiles({ params, files })

      seoEventManager.push({
        event: 'Gstation_Issues_Issue_CreateSuccess',
        payload: {
          interface: interfaceName.issueManagement,
        },
      })

      onCreateIssue?.(issue)
    } catch (error) {
      console.error('error :>> ', error)
    }
  }

  const onCloseHandler = () => {
    onClose?.()
    reset({ ...defaultValues, project: selectedProject })
  }

  const attachFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (!files) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), filesState?.length)

    append(allowFiles)
  }

  const isMaxCountFiles = fields.length >= maxCountFiles

  const onScrollList = useCallback(
    event => {
      if (fetchIssueTemplateListPending) {
        return
      }
      const isScrolledToEnd =
        Number(event.target?.scrollTop) + Number(event.target?.clientHeight) >=
        Number(event.target?.scrollHeight) - 20

      if (isScrolledToEnd && templateList?.length < Number(totalTemplates)) {
        setOffsetTemplateList(prevValue => prevValue + templateLimit)
      }
    },
    [templateList?.length, totalTemplates, fetchIssueTemplateListPending],
  )

  const onResetTemplate = () => {
    setValue('template', null)
  }

  const getStatusItem = useCallback(
    (
      status:
        | api.IssueStatus.Enum.DRAFT
        | api.IssueStatus.Enum.NOT_STARTED
        | null,
    ) => preparedStatusList.find(item => item.status === status),
    [preparedStatusList],
  )

  const defaultSelectedStatus = useMemo(() => {
    if (assignees) {
      return getStatusItem(api.IssueStatus.Enum.NOT_STARTED)
    }

    return getStatusItem(api.IssueStatus.Enum.DRAFT)
  }, [assignees, getStatusItem])

  const contentRef = useRef<HTMLDivElement>(null)

  const contentRefCurrent = contentRef.current

  const existScroll =
    contentRefCurrent?.scrollHeight !== contentRefCurrent?.clientHeight

  return (
    <Container>
      <Header>
        <Title>
          <HeaderTitle>
            Новое замечание{' '}
            <ColorSelectWrapper>
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <ColoredSelect
                    list={preparedStatusList}
                    selectedItem={
                      getStatusItem(field.value) || defaultSelectedStatus
                    }
                    onChange={item => field.onChange(item.status)}
                  />
                )}
              />
            </ColorSelectWrapper>
          </HeaderTitle>
        </Title>
      </Header>
      <Content ref={contentRef}>
        <Form>
          <FieldContainer>
            <FieldLabel required>Проект</FieldLabel>
            <Controller
              name='project'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  data-test-id='projectName'
                  options={projectList}
                  getOptionLabel={(option: smApi.Project) => option.name}
                  placeholder='Выберите проект'
                  error={Boolean(errors.project)}
                  allowDelete={false}
                  lockSelect={lockProjectSelect}
                />
              )}
            />
            <FieldError hidden={!errors.project}>
              {errors.project && 'message' in errors.project
                ? errors.project.message
                : null}
            </FieldError>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>На основе шаблона</FieldLabel>
            <Controller
              name='template'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data-test-id='basedOnTemplate'
                  options={templateList}
                  getOptionLabel={(option: api.IssueTemplate) => option.name}
                  placeholder='Выберите шаблон замечания'
                  onInputChange={debounce((value: string) => {
                    if (value.toLocaleLowerCase() !== templateSearchValue) {
                      setOffsetTemplateList(0)
                      setTemplateSearchValue(value.toLocaleLowerCase())
                    }
                  }, 500)}
                  onScrollList={onScrollList}
                  onReset={onResetTemplate}
                  disabled={!project || loading}
                  loading={false}
                />
              )}
            />
          </FieldContainer>
          <FullSeparator />

          <FieldContainer>
            <FieldLabel required>Название</FieldLabel>
            <Controller
              name='name'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
                maxLength: {
                  message: maxLengthTitleErrorMessage,
                  value: 256,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  data-test-id='issueName'
                  placeholder='Укажите название'
                  error={Boolean(errors.name)}
                  disabled={loading || createIssuePending || !project}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.name}>
              {errors.name && 'message' in errors.name
                ? errors.name.message
                : null}
            </FieldError>
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Срок</FieldLabel>
            <Controller
              name='deadline'
              control={control}
              rules={{
                validate: value => {
                  if (!value) {
                    return true
                  }

                  const isValid =
                    value &&
                    dayjs(value).isValid() &&
                    !dayjs(value).isBefore(new Date().toDateString())

                  return isValid || failDateErrorMessage
                },
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  dataTestIdFor={{ input: 'deadline' }}
                  label='Укажите срок'
                  error={Boolean(errors.deadline)}
                  value={field.value}
                  disabled={loading || createIssuePending || !project}
                  background='#f4f4f8'
                  onReset={() => {
                    setValue('deadline', null)
                    clearErrors('deadline')
                  }}
                  format={DEFAULT_DISPLAY_DATE_FORMAT}
                  disablePast
                />
              )}
            />
            <FieldError hidden={!errors.deadline}>
              {errors.deadline && 'message' in errors.deadline
                ? errors.deadline.message
                : null}
            </FieldError>
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Назначено на</FieldLabel>

            <Controller
              name='assignees'
              control={control}
              render={({ field }) => (
                <SelectByGroupsMultiple
                  {...field}
                  data-test-id='assignedTo'
                  getOptionLabel={(option: AssigneeListItem) => option.label}
                  groups={assigneeGroupList}
                  placeholder='Выберите пользователя/роль/компанию'
                  error={Boolean(errors.assignees)}
                  disabled={loading || createIssuePending || !project}
                  optionDataTestIdPrefix='createIssuePopupSelectAssigneeOption'
                />
              )}
            />
            <FieldError hidden={!errors.assignees}>
              {errors.assignees && 'message' in errors.assignees
                ? (errors.assignees as { message: string }).message //TODO убрать каст
                : null}
            </FieldError>
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Описание</FieldLabel>

            <Controller
              name='description'
              control={control}
              rules={{
                maxLength: {
                  message: maxLengthDescriptionErrorMessage,
                  value: 2048,
                },
              }}
              render={({ field }) => (
                <TextArea
                  {...field}
                  data-test-id='description'
                  error={Boolean(errors.description)}
                  placeholder='Укажите описание'
                  disabled={loading || createIssuePending || !project}
                />
              )}
            />

            <FieldError hidden={!errors.description}>
              {errors.description && 'message' in errors.description
                ? errors.description.message
                : null}
            </FieldError>
          </FieldContainer>

          <FullSeparator />

          <AttributesWrapper>
            {attributesFields.map((attribute, index) => (
              <AttributeFieldItem<FormValue>
                key={attribute.id}
                index={index}
                control={control}
                attributeId={attribute.attributeId}
                attributeName={attribute.name}
                attributesService={attributesService}
                loading={!!fetchAttributePendingMap[attribute.attributeId]}
                optionsLoading={
                  fetchMostRecentIssuePending || fetchAttributeValuesPending
                }
                disabled={loading || createIssuePending || !project}
                attributesFormService={attributesFormService}
                dataTestIdPrefix='createIssuePopupSelectAttribute'
                projectUrn={projectUrn}
              />
            ))}
          </AttributesWrapper>

          <FullSeparator />

          <FieldContainer>
            <FieldLabel>Документы</FieldLabel>

            {fields.length !== 0 && (
              <UploadFilesWrapper>
                {fields.map((item, index) => (
                  <UploadedFile
                    key={item.id}
                    title={filesState?.[index]?.name || ''}
                    onDelete={() => {
                      clearFileErrors()
                      remove(index)
                    }}
                  />
                ))}
              </UploadFilesWrapper>
            )}

            <Tooltip
              title={isMaxCountFiles ? maxCountErrorMessage : ''}
              styleContent={{
                width: 'min-content',
                marginBottom: '10px',
                marginTop: '16px',
              }}
              noMaxWidth
            >
              <AttachFile
                dataTestId='attachFile'
                disabled={
                  isMaxCountFiles || loading || createIssuePending || !project
                }
                buttonText='Прикрепить'
                onChange={attachFile}
                size='small'
                variant='blue'
              />
            </Tooltip>
          </FieldContainer>

          {existScroll && <EmptyContainer />}
        </Form>
      </Content>
      <Footer existScroll={existScroll}>
        <Button
          color='secondary'
          size='regular'
          onClick={onCloseHandler}
          data-test-id='declineToCreateIssue'
        >
          Отменить
        </Button>

        <CreateButton
          onClick={handleSubmit(onSubmit)}
          data-test-id='acceptToCreateIssue'
          disabled={loading || createIssuePending}
        >
          Создать замечание
        </CreateButton>
      </Footer>
    </Container>
  )
}
